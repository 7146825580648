import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useCallback } from 'react';
import { Breadcrumbs } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
import { FigmaEmbed } from '../common/FigmaEmbed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breadcrumbs"
    }}>{`Breadcrumbs`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Breadcrumbs } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/?node-id=6604%3A14710'} storybook={'/?path=/story/breadcrumbs-breadcrumbs--playground'} vue={'/components/HseBreadcrumbs/HseBreadcrumbs.html'} vueStorybook={'/?path=/story/breadcrumbs-breadcrumbs--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Breadcrumbs – компонент хлебных крошек.`}</p>
    <p>{`Хлебные крошки помогают пользователям перемещаться между разными уровнями сайта и понимать, где они находятся сейчас.`}</p>
    <p>{`Передавайте крошки в проп `}<inlineCode parentName="p">{`items`}</inlineCode>{` в виде массива объектов, реализующих интерфейс `}<inlineCode parentName="p">{`BreadcrumbsItem`}</inlineCode>{`.`}</p>
    <p>{`Для выделения текущей страницы среди крошек, передайте соответствующему элементу массива `}<inlineCode parentName="p">{`items`}</inlineCode>{` свойство `}<inlineCode parentName="p">{`active`}</inlineCode>{` со значением `}<inlineCode parentName="p">{`true`}</inlineCode>{`.
Обычно текущая страница является последней крошкой.
Вы можете не выводить текущую страницу среди хлебных крошек, если сразу под ними есть заголовок этой страницы.`}</p>
    <p>{`У каждого объекта массива `}<inlineCode parentName="p">{`items`}</inlineCode>{` должен быть `}<inlineCode parentName="p">{`label`}</inlineCode>{`. `}<inlineCode parentName="p">{`label`}</inlineCode>{` передается в `}<inlineCode parentName="p">{`children`}</inlineCode>{` компонента `}<inlineCode parentName="p">{`linkComponent`}</inlineCode>{`.
Кроме `}<inlineCode parentName="p">{`label`}</inlineCode>{` вы можете передать атрибут `}<inlineCode parentName="p">{`href`}</inlineCode>{` для задания целевой ссылки.`}</p>
    <Playground __position={1} __code={'<Breadcrumbs\n  items={[\n    {\n      label: \'Главная\',\n      href: \'/\',\n    },\n    {\n      label: \'Компоненты\',\n      href: \'/components\',\n    },\n    {\n      label: \'Breadcrumbs\',\n      href: \'/src-components-breadcrumbs\',\n      active: true,\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      Breadcrumbs,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Breadcrumbs items={[{
        label: 'Главная',
        href: '/'
      }, {
        label: 'Компоненты',
        href: '/components'
      }, {
        label: 'Breadcrumbs',
        href: '/src-components-breadcrumbs',
        active: true
      }]} mdxType="Breadcrumbs" />
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=9283%3A31529'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=9285%3A34618'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "произвольный-компонент-ссылки"
    }}>{`Произвольный компонент ссылки`}</h3>
    <p>{`По умолчанию для отображения крошек используется элемент anchor (тег `}<inlineCode parentName="p">{`a`}</inlineCode>{`).`}</p>
    <p>{`Вы можете передать произвольный компонент хлебной крошки в проп `}<inlineCode parentName="p">{`linkComponent`}</inlineCode>{`,
например `}<inlineCode parentName="p">{`Link`}</inlineCode>{` из `}<inlineCode parentName="p">{`react-router`}</inlineCode>{` (чтобы не путать его с нашим `}<inlineCode parentName="p">{`Link`}</inlineCode>{` мы будем называть его `}<inlineCode parentName="p">{`RouterLink`}</inlineCode>{`).
Для этого используйте свойство `}<inlineCode parentName="p">{`linkComponent={RouterLink}`}</inlineCode>{` и передайте необходимые пропы (`}<inlineCode parentName="p">{`to`}</inlineCode>{`) как часть массива `}<inlineCode parentName="p">{`items`}</inlineCode>{`.`}</p>
    <Playground __position={4} __code={'() => {\n  return (\n    <Breadcrumbs\n      linkComponent={\'button\'}\n      items={[\n        {\n          label: \'Главная\',\n        },\n        {\n          label: \'Компоненты\',\n        },\n        {\n          label: \'Breadcrumbs\',\n          active: true,\n        },\n      ]}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      Breadcrumbs,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return <Breadcrumbs linkComponent={'button'} items={[{
          label: 'Главная'
        }, {
          label: 'Компоненты'
        }, {
          label: 'Breadcrumbs',
          active: true
        }]} mdxType="Breadcrumbs" />;
      }}
    </Playground>
    <h3 {...{
      "id": "реализация-переходов"
    }}>{`Реализация переходов`}</h3>
    <p>{`Для реализации переходов по страницам вы можете передать атрибут `}<inlineCode parentName="p">{`href`}</inlineCode>{`, добавить обработчик `}<inlineCode parentName="p">{`onItemClick`}</inlineCode>{`,
либо передать любой другой атрибут в соответствии с `}<inlineCode parentName="p">{`linkComponent`}</inlineCode>{`.`}</p>
    <p>{`При использовании обработчика `}<inlineCode parentName="p">{`onItemClick`}</inlineCode>{`, необходимо передать `}<inlineCode parentName="p">{`value`}</inlineCode>{` для каждой хлебной крошки.
В обработчик `}<inlineCode parentName="p">{`onItemClick`}</inlineCode>{` первым аргументом будет передаваться `}<inlineCode parentName="p">{`value`}</inlineCode>{`, вторым – событие клика.
Это может быть полезно для работы с `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/History_API"
      }}>{`History API`}</a></p>
    <Playground __position={5} __code={'() => {\n  const handleClick = useCallback((value, event) => {\n    event.preventDefault()\n  }, [])\n  return (\n    <Breadcrumbs\n      onItemClick={handleClick}\n      items={[\n        {\n          label: \'Главная\',\n          value: \'main-page\',\n        },\n        {\n          label: \'Вторая страница\',\n          value: \'second-page\',\n        },\n        {\n          label: \'Третья страница\',\n          active: true,\n        },\n      ]}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useCallback,
      Breadcrumbs,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const handleClick = useCallback((value, event) => {
          event.preventDefault();
        }, []);
        return <Breadcrumbs onItemClick={handleClick} items={[{
          label: 'Главная',
          value: 'main-page'
        }, {
          label: 'Вторая страница',
          value: 'second-page'
        }, {
          label: 'Третья страница',
          active: true
        }]} mdxType="Breadcrumbs" />;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Breadcrumbs} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      